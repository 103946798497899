// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-template-js": () => import("./../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-about-template-js": () => import("./../src/templates/AboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-sub-page-template-js": () => import("./../src/templates/SubPageTemplate.js" /* webpackChunkName: "component---src-templates-sub-page-template-js" */),
  "component---src-templates-service-template-js": () => import("./../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complaints-procedure-js": () => import("./../src/pages/complaints-procedure.js" /* webpackChunkName: "component---src-pages-complaints-procedure-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ethics-js": () => import("./../src/pages/ethics.js" /* webpackChunkName: "component---src-pages-ethics-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-website-terms-of-use-js": () => import("./../src/pages/website-terms-of-use.js" /* webpackChunkName: "component---src-pages-website-terms-of-use-js" */)
}

