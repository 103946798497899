const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  addScript("/js/leadfeeder.js")
}
